import Wave from '../../../images/vector/wave-top.svg';
import './section-about.css';
import Equipment from '../../../images/raster/equipment.jpg';
import Personal from '../../../images/raster/personal.jpg';
import Price from '../../../images/raster/price.png';
import Watchout from '../../../images/raster/watchout.jpg';
import { dentaltoolIcoAlt, doctorIcoAlt, googlemapsIcoAlt, patitentIcoAlt, walletIcoAlt, yandexmapsIcoAlt } from '../../../config/semantic-constants';
import GMaps from '../../../images/vector/Google_Maps.svg';
import YMaps from '../../../images/vector/Yandex_Maps.svg';
import { CL_NAME, EMAIL, GMAPS_LINK, PHONE_PRIMARY, PHONE_SECONDARY, YMAPS_LINK } from '../../../config/const';
import { phoneFormatter } from '../../../utils/phone_processing';


const SectionAbout = () => {
    return  (
        <section
            className= "landing-doc-section" 
            id = "about"
        >
            <img src = {Wave} alt='' className = 'divider-top'/>
            <h2 className = 'caption-bright'>
                О {CL_NAME}
            </h2>
            <p 
                className = 'center'
                style={{
                    marginTop: '10vh'
                }}
            >
                Наша клиника предоставляет широкий спектр услуг, начиная от профилактики и профессиональной гигиены ротовой полости, заканчивая протезированием,
                имплантацией, ортодонтией и пародонтологией. А наши цены и теплый прием не оставят вас равнодушными.
            </p>
            <p className = 'about-text'>
                В медицинском центре {CL_NAME} работают опытные специалисты с высшим образованием, которые обладают большим опытом.
                Наша стоматология оснащена современным высокотехнологичным оборудованием, позволяющим оперативно выполнять работы любого уровня сложности.
                Стоматологи используют на практике только проверенные, надежные и безопасные материалы, что гарантирует высокое качество предоставленных услуг.
            </p>
            <p className = 'about-text'>
                Мы работаем ежедневно с 10:00 до 20:00 для вас. Вы можете записаться на бесплатную консультацию, позвонив по номеру <a href = {`tel:${phoneFormatter(PHONE_PRIMARY)}`}>
                {PHONE_PRIMARY}</a> или <a href = {`tel:${phoneFormatter(PHONE_SECONDARY)}`}>{PHONE_SECONDARY}</a>, написав нам на почту <a href = {`mailto:${EMAIL}`}>{EMAIL}</a> или заказать звонок. 
            </p>
            <img src = 'images/raster/Interior/Panorama.webp' alt = {`Приемная ${CL_NAME}`} className='about-panorama'/>
            <div className = 'about-overview'>
                <div className = 'about-overview-card'>
                    <div className = 'about-overview-img'>
                        <img src = {Price} alt = {walletIcoAlt} />
                    </div>
                    <h3 className = 'center'>Доступные цены</h3>
                    <p className = 'center'>
                        Наши цены и постоянные скидки на услуги приятно вас удивят. Лечить зубы это недорого!
                    </p>
                </div>
                <div className = 'about-overview-card'>
                    <div className = 'about-overview-img'>
                        <img src = {Personal} alt = {doctorIcoAlt} />
                    </div>
                    <h3 className = 'center'>Квалифицированный персонал</h3>
                    <p className = 'center'>
                        Наши квалифицированные специалисты смогут справиться с проблемами любой сложности и составят для вас надежный план лечения.
                    </p>
                </div>
                <div className = 'about-overview-card'>
                    <div className = 'about-overview-img'>
                        <img src = {Equipment} alt = {dentaltoolIcoAlt}/>
                    </div>
                    <h3 className = 'center'>Современное оборудование</h3>
                    <p className = 'center'>
                        Современная аппаратура и собственная лаборатория позволят выполнить любые необходимые работы. 
                    </p>
                </div>
                <div className = 'about-overview-card'>
                    <div className = 'about-overview-img'>
                        <img src = {Watchout} alt = {patitentIcoAlt}/>
                    </div>
                    <h3 className = 'center'>Индивидуальный подход</h3>
                    <p className = 'center'>
                        Индивидуальный подход к каждому пациенту гарантирует комфортные условия лечения без боли и страха. Вне зависимости от сложности работ и возраста пациента.
                    </p>
                </div>
            </div>
            <div className = 'reviews-row'>
                <div className = 'reviews-card'>
                    <div className = 'reviews-card-image'>
                        <a href = {YMAPS_LINK} target='_blank' rel="noreferrer">
                            <img src = {YMaps} alt = {yandexmapsIcoAlt} className='review-ico'/>
                        </a>
                    </div>
                    <div className = 'reviews-card-text center'>
                        <a href = {YMAPS_LINK} target='_blank' rel="noreferrer">
                            Отзывы о нас на Яндекс.Картах
                        </a>
                    </div>
                </div>
                <div className = 'reviews-card'>
                    <div className = 'reviews-card-image'>
                        <a href = {GMAPS_LINK} target='_blank' rel="noreferrer">
                            <img src = {GMaps} alt = {googlemapsIcoAlt} className='review-ico'/>
                        </a>
                    </div>
                    <div className = 'reviews-card-text center'>
                        <a href = {GMAPS_LINK} target='_blank' rel="noreferrer">
                            Отзывы о нас на Google Maps
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionAbout;