import './section-contacts.css';
import { EMAIL, PHONE_PRIMARY, PHONE_SECONDARY } from '../../../config/const';
import { phoneFormatter } from '../../../utils/phone_processing';

const SectionContacts = () => {
/*
    const video = document.getElementById('background-video') as HTMLVideoElement;
    video.addEventListener('ended', () => {
      video.currentTime = 0;
      video.play();
    });
*/
    return (
        <section 
            className = 'landing-doc-section'
            style={{
                background: '#194847',
                color: '#FFF'
            }}
            id = "contacts"
        >
        <h2>
            Контакты
        </h2>
            <p className = 'about-text'>
                Мы находимся по адресу: 127473, город Москва, Переулок Чернышевского, дом 11 строение 1.
            </p>
            <p className = 'about-text'>
                Станция метро: Достоевская или Новослободская
            </p>
            <p className = 'about-text'>
                Всю необходимую информацию вы можете узнать по телефонам: <a href = {`tel:${phoneFormatter(PHONE_PRIMARY)}`}>
                    {PHONE_PRIMARY}
                </a> и <a href = {`tel:${phoneFormatter(PHONE_SECONDARY)}`}>
                    {PHONE_SECONDARY}
                </a>
            </p>
            <p className = 'about-text'>
                Наша электронная почта: <a href = {`mailto:${EMAIL}`}>{EMAIL}</a>
            </p>
            <p className = 'about-text'>
                Мы работаем ежедневно с 10:00 до 20:00.
            </p>
            <div className = 'about-mapwrapper'>
                <img src= '/images/raster/Exterior/entrance2.jpg' alt ='' />
                <iframe 
                    src = 'https://yandex.ru/map-widget/v1/?lang=ru_RU&scroll=true&um=constructor%3A30f8e074d419a833b2dc9652e1e2dbbc90990a2a9d5a010fd3d6f7bf44873497'
                    allowFullScreen = {true}
                    style = {{display: 'block'}}
                    width = {'50%'}
                    height = {'100%'}
                    title = 'Well Yandex map'
                />
            </div>
        </section>
    )
}

export default SectionContacts