import Inv from '../images/raster/Investigation.webp';
import Shtraumann from '../images/raster/shtraumann.jpg';
import cora from '../images/raster/service-bg.jpg';

interface Slide {
    description?: string
    img: string
    img_alt: string
}


export const slides:Slide[] =  [
    {
        img: 'images/raster/Exterior/Window.webp',
        img_alt: 'Экстерьер Well Clinic',
    },
    {
        img: 'images/raster/Assets/Zoom43.webp',
        img_alt: 'Вход в Well Clinic'
    },
    {
        img: Inv,
        img_alt: 'Осмотр у Амбарчяна С.Г.'
    },
    {
        img: cora,
        img_alt: 'Процесс лечения кариеса'
    },
    {
        img: Shtraumann,
        img_alt: 'Импланты Штрауман'
    }
]
