import { PHONE_PRIMARY, PHONE_SECONDARY } from '../../config/const';
import './footer.css';

const Footer =  () => {
    return (
        <footer className = 'footer'>
            <div className = 'footer-wrapper'>
                <div className = 'footer-section'>
                    <h3>
                        Услуги нашей стоматологии
                    </h3>
                    <div className = 'list-wrapper'>
                        <ul>
                            <li>
                                Профилактика и лечение
                            </li>
                            <li>
                                Хирургическая стоматология
                            </li>
                            <li>
                                Ортопедическая стоматология
                            </li>
                            <li>
                                Пародонтология
                            </li>
                        </ul>
                        <ul>
                            <li>
                                Имплантология
                            </li>
                            <li>
                                Рентген
                            </li>
                            <li>
                                Ортодонтия
                            </li>
                        </ul>
                    </div>
                    <h4>
                        <span className='stanisgrox-link'>
                            Разработка и дизайн сайта - <a href='https://stanisgrox.com/' hrefLang='en' target='_blank' rel="noreferrer">Stanisgrox.com</a>
                        </span>
                    </h4>
                </div>
                <div className = 'footer-section'>
                    <h3>Наши контакты</h3>
                    <p>
                        метро Достоевская/Новослободская,
                        Пер. Чернышевского, д. 11 стр.1
                    </p>
                    <h4>
                        {PHONE_PRIMARY}
                    </h4>
                    <h4>
                        {PHONE_SECONDARY}
                    </h4>
                </div>
                <div className = 'footer-section'>
                    <p className = 'footer-disclaimer'>
                        Имеются противопоказания, необходима консультация специалиста
                        Обращаем ваше внимание на то, что данный интернет-сайт носит исключительно 
                        информационный характер и ни при каких условиях не является публичной 
                        офертой, определяемой положениями Статьи 437(2) Гражданского 
                        кодекса Российской Федерации.
                    </p>
                    <p>
                        Политика обработки персональных данных
                    </p>
                    <p>
                        Возрастное ограничение 18+
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer