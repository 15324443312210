import './section-services.css';
import Wave from '../../../images/vector/wave-services.svg';
import Wave2 from '../../../images/vector/wave-doctors-top.svg';
import Surgery from '../../../images/raster/surgery_method.jpg';
import Ortho from '../../../images/raster/orthodent_method.jpg';
import Implant from '../../../images/raster/implant_method.jpg';
import Orthopedic from '../../../images/raster/orthopedic_method.jpg';
import Paradent from '../../../images/raster/paradent.jpg';
import Preheal from '../../../images/raster/preheal_method.jpg';
import { useState } from 'react';

import cross from '../../../images/vector/cross.svg';
import { crossIcoAlt } from '../../../config/semantic-constants';

import pricelistBuilder from '../../../utils/pricelist-builder';
import therapyList from '../../../config/pricelist-therapy.json';
import orthopedyList from '../../../config/pricelist-orthopedy.json';
import orthodontyList from '../../../config/pricelist-orthodonty.json';
import surgeryList from '../../../config/pricelist-surgery.json';
import parodentLitst from '../../../config/pricelist-parodent.json';

const TableCaption = ():JSX.Element => {
    return (
        <table>
            <thead>
                <tr>
                    <td className = "pricelist-naming">
                        <b>Название услуги</b>
                    </td>
                    <td className = "pricelist-price">
                        <b>Цена, руб.</b>
                    </td>
                </tr>
            </thead>
        </table>
    )
}



const SectionServices = () => {

    const [priceList, switchPriceList] = useState(false);

    return (
        <section 
            className = 'landing-doc-section'
            style={{
                color: '#FFF',
                backgroundImage : `url(${Wave})`
            }}
            id = "services"
        >
            <h2 className = 'caption-dark'>
                Услуги
            </h2>
            <div className='services-wrapper'>      
                <div className = 'services-grid'>
                    <div className = 'services-grid-card'>
                        <div>
                            <img src = {Preheal} alt = '' className = 'box-hoverable'/>
                        </div>
                        <h3>
                            Профилактика и лечение
                        </h3>
                    </div>
                    <div className = 'services-grid-card'>
                        <div>
                            <img src = {Surgery} alt = 'Подготовка хирургических инструментов' className = 'box-hoverable'/>
                        </div>
                        <h3>
                            Хирургическая стоматология
                        </h3>
                    </div>
                    <div className = 'services-grid-card'>
                        <div>
                            <img src = {Orthopedic} alt = '' className = 'box-hoverable'/>
                        </div>
                        <h3>
                            Ортопедическая стоматология
                        </h3>
                    </div>
                    <div className = 'services-grid-card'>
                        <div>
                            <img src = {Paradent} alt = '' className = 'box-hoverable'/>
                        </div>
                        <h3>
                            Пародонтология
                        </h3>
                    </div>
                    <div className = 'services-grid-card'>
                        <div>
                            <img src = {Implant} alt = 'Изображение зубного импланта' className = 'box-hoverable'/>
                        </div>
                        <h3>
                            Имплантология
                        </h3>
                    </div>
                    <div className = 'services-grid-card'>
                        <div>
                            <img src = {Ortho} alt = 'Диагностика брекетов' className = 'box-hoverable'/>
                        </div>
                        <h3>
                            Ортодонтия
                        </h3>
                    </div>
                    <div className = 'services-grid-button'>
                        <button 
                            className='action'
                            onClick={() => switchPriceList(!priceList)}
                        >
                            Наши цены
                        </button>
                    </div>
                </div>
            </div>
            <img src={Wave2} alt = '' className='divider-bottom'/>
            <div
                className = 'order-call-wrapper'
                style={{
                    display: priceList? 'block' : 'none',
                    color: '#194847'
                }}
            >
                <div className = 'order-call-form price-list'>
                    <button 
                        className = 'order-call-form-close'
                        onClick = {() => switchPriceList(!priceList)}
                    >
                        <img 
                            src = {cross} 
                            alt = {crossIcoAlt}
                        />
                    </button>
                    <h4>
                        Терапия
                    </h4>
                    <TableCaption />
                    <table>
                        {pricelistBuilder(therapyList)}
                    </table>
                    <h4>
                        Ортопедия
                    </h4>
                    <TableCaption />
                    <table>
                        {pricelistBuilder(orthopedyList)}
                    </table>
                    <h4>
                        Ортодонтия
                    </h4>
                    <TableCaption />
                    <table>
                        {pricelistBuilder(orthodontyList)}
                    </table>
                    <h4>
                        Хирургия
                    </h4>
                    <TableCaption />
                    <table>
                        {pricelistBuilder(surgeryList)}
                    </table>
                    <h4>
                        Пародонтология
                    </h4>
                    <TableCaption />
                    <table>
                        {pricelistBuilder(parodentLitst)}
                    </table>
                </div>
            </div>
        </section>
    )
}

export default SectionServices;