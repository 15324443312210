import { useEffect, useState } from 'react';
import './home-slider.css';
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa';
import { SLIDER_INTERVAL } from '../../../config/const';


type Props = {
    children?: string | JSX.Element | JSX.Element[]
}

const PAGE_WIDTH = 100;

const ModularSlider = ({children}: Props) => {
    const [offset, setOffset] = useState(0);
    const [atEnd, setEnd] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => RArrow(offset), SLIDER_INTERVAL);
        return () => clearInterval(interval)
    });

    const RArrow = (offset:number) => {
        let newOffset = offset -  PAGE_WIDTH;
        if  (newOffset  < -400) {
            setEnd(true)
            setOffset(0);
            return;
        }
        setOffset(newOffset);
        setEnd(false);   
    }

    const LArrow = (offset:number) => {
        let newOffset = offset +  PAGE_WIDTH;
        setOffset(Math.min(newOffset,0));
    }

    return(
        <div className = 'main-container'>
            <FaChevronLeft 
                className = 'arrow'
                onClick = {() => LArrow(offset)}
            />
            <div className = 'window'>
                <div 
                    className='items-container'
                    style={{
                        transform: `translateX(${offset}vw)`,
                        transitionProperty: atEnd? 'none'  :  'transform'
                    }}
                >
                    {children}
                </div>
            </div>
            <FaChevronRight 
                className = 'arrow'
                onClick = {() => RArrow(offset)}
            />
        </div>
    )
}

export default ModularSlider;