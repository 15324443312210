import { slides } from "../../../../config/main-slider"

interface Props{
    slideNumber: number
    lazy?: boolean
}


const SlideThree = (props:Props) => {
    return (
        <div className = {`carousel-item`}>
            <img src = {slides[props.slideNumber].img} alt = {slides[props.slideNumber].img_alt} className = "carousel-image" loading = {props.lazy? 'lazy' : 'eager'}/>
            <div 
                className = "carousel-item-card card-two"
                style={{
                    display: 'flex'
                }}
            >
                <h2>
                    Счастливые часы
                </h2>
                <h3>
                    Терапевтическое лечение и удаление зубов со скидкой 15%
                </h3>
                <h4>
                    <b>C 14:00 до 16:00</b>
                </h4>
            </div>
        </div>
    )
}

export default SlideThree;