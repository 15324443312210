const pricelistBuilder = (json:any) =>{

    const renderRow = ():JSX.Element[] => {
        return (
            json.map((row: any) => {
                return (
                    <tr key = {row.id}>
                        <td className = "pricelist-naming">
                            {row.name}
                        </td>
                        <td className = "pricelist-price">
                            {row.price}
                        </td>
                    </tr>
                )
            })
        )
    }

    return (
        <tbody>
            {renderRow()}
        </tbody> 
    )
}

export default pricelistBuilder;