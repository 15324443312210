import React, { useState } from 'react';
import './header.css';
import { PHONE_PRIMARY, PHONE_SECONDARY } from '../../config/const';
import { phoneFormatter } from '../../utils/phone_processing';
import phoneIcoSrc from '../../images/vector/phone.svg';
import metroIcoSrc from '../../images/vector/metro.svg';
import buildingIco from '../../images/vector/building.svg';
import cross from '../../images/vector/cross.svg';
import { buildingIcoAlt, crossIcoAlt, metroIcoAlt, phoneIcoAlt } from '../../config/semantic-constants';
import axios from 'axios';

export type PopupProps = {
    ordercallF: (ordercallF: boolean) => void;
    ordercallV?: boolean;
    docF?: (docF: string) => void;
    docV?: string
}

let config = {
    headers: {
        'Content-Type': 'application/json'
    }
}

const ScrollTo = (sectionName: string) => {
    const section = document.querySelector(`#${sectionName}`);
    section?.scrollIntoView({behavior: 'smooth', block: 'start'})
}

const CallMe = (name: string, phone: string, setError: React.Dispatch<React.SetStateAction<string>>) => {
    if(!name) {
        setError('Пожалуйста, введите ваше имя'); 
        return;
    }
    if(!phone) {
        setError('Пожалуйста, введите ваш номер телефона'); 
        return;
    }
    axios.post('https://api.stanisgrox.com/callback', {
        name: name,
        phone: phone
    }, config)
    .then((res) =>{
        console.log(res);
        window.location.reload();
    }, (error) => {
        console.log(error);
        setError('При отправке сообщения произошла ошибка. Пожалуйста, повторите попытку или перезвоните нам.')
    })
}


const Header = ({ordercallF, ordercallV}:PopupProps) => {

    const [name, changeName] = useState('');
    const [phone, changePhone] = useState('');
    const [error, setError] = useState('');

    return (
        <header>
            <nav className = 'navbar'>
                <div className = 'navbar-topsection'>
                    <div className = 'navbar-topsection-logo'>
                        <img src = 'images/raster/Assets/logo.webp' alt = 'Well Clinic logo'/>
                    </div>
                    <img src = 'images/raster/Assets/logo-min.webp' id = "mobile-logo" alt = 'Well Clinic logo'/>
                    <div className = 'credentials-phone'>
                        <div className = 'credentials-phone-text'>
                            <img src = {phoneIcoSrc} alt = {phoneIcoAlt} className='inline-img'/>
                            <a href = {`tel:${phoneFormatter(PHONE_PRIMARY)}`}>
                                {PHONE_PRIMARY}
                            </a>
                            |
                            <a href = {`tel:${phoneFormatter(PHONE_SECONDARY)}`}>
                                {PHONE_SECONDARY}
                            </a>
                        </div>
                        <div className = 'credentials-phone-ordercall'>
                            <button 
                                className = 'action'
                                onClick = {() => ordercallF(true)}
                                id = 'header-ordercall'
                            >
                                Заказать звонок
                            </button>
                        </div>
                    </div>
                    <div className = 'credentials-adress'>
                        <div className  = 'credentials-adress-metro'>
                            <img src = {metroIcoSrc} alt = {metroIcoAlt} className = {`credentials-adress-metro-img img-novoslobodskaya`} />
                            {'Новослободская'}
                            |
                            <img src = {metroIcoSrc} alt = {metroIcoAlt} className = {`credentials-adress-metro-img img-dostoevskaya`} />
                            {'Достоевская'}
                        </div>
                        <div className  = 'credentials-adress-exact'>
                            <img src = {buildingIco} alt = {buildingIcoAlt} className = {`credentials-adress-metro-img`} />
                            Чернышевского пер., 11с1
                        </div>
                    </div>
                </div>
                <div className = 'navbar-botsection'>
                    <ul className = 'navbar-navigation'>
                        <li onClick = {() => ScrollTo('about')}>О нас</li>
                        <li onClick = {() => ScrollTo('services')}>Услуги</li>
                        <li onClick = {() => ScrollTo('doctors')}>Врачи</li>
                        <li onClick = {() => ScrollTo('contacts')}>Контакты</li>
                    </ul>
                </div>
            </nav>
            <div
                className = 'order-call-wrapper'
                style={{
                    display: ordercallV? 'block' : 'none'
                }}
            >
                <div className = 'order-call-form'>
                    <button 
                        className = 'order-call-form-close'
                        onClick = {() => ordercallF(!ordercallV)}
                    >
                        <img 
                            src = {cross} 
                            alt = {crossIcoAlt}
                        />
                    </button>
                    <h3 className = 'center'>
                        Укажите свое имя и телефон и мы перезвоним вам
                    </h3>
                    <p className = 'center'>
                        Наш график работы: 
                        Ежедневно 10:00 - 20:00
                    </p>
                    <p id = 'errors'>
                        {error}
                    </p>
                    <form>
                        <label htmlFor='inputname'>
                            Ваше имя
                        </label>
                        <input 
                            type = "text" 
                            placeholder = "Ваше имя" 
                            id = "inputname" 
                            onInput={e =>{
                                let currentInput = e.currentTarget;
                                changeName(currentInput.value);
                                setError('');
                            }}
                        />
                        <label htmlFor='inputphone'>
                            Ваш телефон
                        </label>
                        <input 
                            type = "tel" 
                            id = "inputphone"
                            onInput={e =>{
                                let currentInput = e.currentTarget;
                                changePhone(currentInput.value);
                                setError('');
                            }}
                        />
                        <button 
                            className = 'action'
                            type = 'button'
                            onClick = {() => CallMe(name,phone,setError)}
                        >
                            Отправить
                        </button>
                    </form>
                </div>
            </div>
        </header>
    )
}

export default Header;