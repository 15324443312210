import { useState } from 'react';
import './section-doctors.css';
import { PopupProps } from '../../header/header';


const getDocDesc = (doc:string): string => {
    switch (doc) {
        case 'Ambarchyan':
            return 'Больше двадцати трех лет опыта работы в области импланталогии и хирургии. Большой опыт в перелечивании пациентов с осложнениями, возникшими после неудачных имплантаций и нерационального протезирования, обратившихся за помощью из других клиник. Поставлю недостающие зубы, уберу те, которые мешают вашему здоровью.';
        case 'Pulkina':
            return 'Стоматолог в третьем поколении. С детства меня окружали классные специалисты и я тянулась за ними. Я подарю вам 32 повода для радости.';
        case 'Pogosyan':
            return 'Дам опору и защиту вашим зубам с помощью современных конструкций. Восстановлю дефекты после челюстно-лицевых операций.';
        case 'Malakhova':
            return 'Верну всех нарушителей в строй. Сделаю вашу улыбку счастливой.';
        case 'Karina':
            return 'Опытный врач с двадцатью годами практики и тысячами довольных пациентов.';
    }
    return '';
}


const SignUp = ({ordercallF, docF}:PopupProps) => {
    return (
        <div className = 'doc-list-card-button'>
            <button 
                className = 'action'
                onClick = {() => ordercallF(true)}
            >
                Записаться
            </button>
        </div>
        
    )
}


const SectionDoctors = ({ordercallF, docF}: PopupProps) => {
    const [doctor, setDoctor] = useState('Ambarchyan');

    return  (
        <section className= "landing-doc-section" id = "doctors">
            <h2>
                Наши врачи
            </h2>
            <div className = 'doc-list'>
                <div 
                    className = 'doc-list-card box-hoverable'
                    onClick ={() =>setDoctor('Ambarchyan')}
                >
                    <h3>
                        Амбарчян
                    </h3>
                    <h4>
                        Степан Григорьевич
                    </h4>
                    <img src = 'images/raster/doctors/Ambarchyan.webp' alt = 'Фото доктора Амбарчяна' loading = 'lazy'/>
                    <h5>
                        Главный врач - хирург-имплантолог
                    </h5>
                    <SignUp ordercallF = {ordercallF} docF={docF} />
                </div>
                <div 
                    className = 'doc-list-card box-hoverable'
                    onClick ={() =>setDoctor('Pulkina')}
                >
                    <h3>
                        Пулькина 
                    </h3>
                    <h4>
                        Виктория Олеговна
                    </h4>
                    <img src = '/images/raster/doctors/Viktoria.webp' alt = 'Фото доктора Пулькиной' loading = 'lazy'/>
                    <h5>
                        Терапевт-пародонтолог
                    </h5>
                    <SignUp ordercallF = {ordercallF} docF={docF} />
                </div>
                <div 
                    className = 'doc-list-card box-hoverable'
                    onClick ={() =>setDoctor('Pogosyan')}
                >
                    <h3>
                        Погосян
                    </h3>
                    <h4>
                        Роланд Робертович
                    </h4>
                    <img src = 'images/raster/doctors/Pogosyan.webp' alt = 'Фото доктора Погосяна' loading = 'lazy'/>
                    <h5>
                        Стоматолог-ортопед, челюстно-лицевой ортопед
                    </h5>
                    <SignUp ordercallF = {ordercallF} docF={docF} />
                </div>
                <div 
                    className = 'doc-list-card box-hoverable'
                    onClick ={() =>setDoctor('Malakhova')}
                >
                    <h3>
                        Малахова
                    </h3>
                    <h4>
                        Юлия Владимировна
                    </h4>
                    <img src = 'images/raster/doctors/Malakhova.webp' alt = 'Фото доктора Малаховой' loading = 'lazy'/>
                    <h5>
                        Ортодонт
                    </h5>
                    <SignUp ordercallF = {ordercallF} docF={docF} />
                </div>
                <div 
                    className = 'doc-list-card box-hoverable'
                    onClick ={() =>setDoctor('Karina')}
                >
                    <h3>
                        Парсаданян
                    </h3>
                    <h4>
                        Карине Альбертовна
                    </h4>
                    <img src = 'images/raster/doctors/Karina.JPG' alt = 'Фото доктора Парсаданян' loading = 'lazy'/>
                    <h5>
                        Стоматолог-терапевт
                    </h5>
                    <SignUp ordercallF = {ordercallF} docF={docF} />
                </div>
            </div>
            <div className = 'doc-about-wrapper'>
                <p>
                    {getDocDesc(doctor)}
                </p>
            </div>
        </section>
    )
}

export default SectionDoctors;

//Большой опыт в перелечивании пациентов с осложнениями, возникшими после неудачных имплантаций и нерационального протезирования, обратившихся за помощью из других клиник.