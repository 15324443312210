//Utility-иконки сайта
export const phoneIcoAlt = 'Иконка телефона';
export const metroIcoAlt = 'Иконка московского метро';
export const buildingIcoAlt = 'Иконка здания';
export const googlemapsIcoAlt = 'Иконка Google Maps';
export const yandexmapsIcoAlt = 'Иконка карт Яндекса';
export const crossIcoAlt = 'Закрыть';

//Representative-иконки сайта
export const walletIcoAlt = 'Иконка кошелька';
export const doctorIcoAlt = 'Иконка доктора';
export const dentaltoolIcoAlt = 'Иконка зуба с стетоскопом';
export const patitentIcoAlt = 'Иконка пациента в кресле';

