//Телефоны в том формате, в котором они будут отображены на сайте
export const PHONE_PRIMARY = '+7 (495) 681-33-52';
export const PHONE_SECONDARY = '+7 (929) 604-40-47';
//Частота смены слайдов в 1 секции (мс)
export const SLIDER_INTERVAL = 5000;
//Ссылки на соцсети и аггрегаторы
export const GMAPS_LINK = 'https://goo.gl/maps/nf6t1AnvfabTr6rx6';
export const YMAPS_LINK = 'https://yandex.ru/maps/-/CCUcFPgmgA';
//email клиники
export const EMAIL = 'stom.step@mail.ru';
//Название клиники
export const CL_NAME = 'Well Clinic';