import ModularSlider from "../../modules/slider/home-slider";
import Slide from "../../modules/slider/slide/home-slide";
import SlideTwo from "../../modules/slider/slide/home-slide2";
import SlideThree from "../../modules/slider/slide/home-slide3";
import SlideFour from "../../modules/slider/slide/home-slide4";
import SlideFive from "../../modules/slider/slide/home-slide5";
import './section-slider.css';


const SectionSlider = () => { 
    return (
        <section className = "landing-top-section">
            <ModularSlider>
                <Slide slideNumber = {0} lazy = {false} />
                <SlideTwo slideNumber = {1} lazy = {true} />
                <SlideThree slideNumber = {2} lazy = {true} />
                <SlideFour slideNumber = {3} lazy = {true} />
                <SlideFive slideNumber = {4} lazy = {true} />
            </ModularSlider>
        </section>
    )
}

export default SectionSlider;