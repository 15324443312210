import React from 'react';
import Header from './components/header/header';
import SectionSlider from './components/sections/section-slider/section-slider';
import Footer from './components/footer/footer';
import SectionDoctors from './components/sections/section-doctors/section-doctors';
import SectionContacts from './components/sections/section-contacts/section-contacts';
import SectionServices from './components/sections/section-services/section-services';
import SectionAbout from './components/sections/section-about/section-about';

function App() {
  //State кнопок "заказать звонок" и "записаться к врачу"
  const [ordercall, openOrder] = React.useState<boolean>(false);
  //State врача
  const [currentDoctor, setDoc] = React.useState<string>('');

  return (
    <div className="App">
      <Header ordercallF = {openOrder} ordercallV = {ordercall} />
      <SectionSlider />
      <SectionAbout />
      <SectionServices />
      <SectionDoctors ordercallF = {openOrder} ordercallV = {ordercall} docF = {setDoc} docV = {currentDoctor} />
      <SectionContacts />
      <Footer />
    </div>
  );
}

export default App;
