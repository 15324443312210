import { slides } from "../../../../config/main-slider"

interface Props{
    slideNumber: number
    lazy?: boolean
}


const Slide = (props:Props) => {
    return (
        <div className = {`carousel-item`}>
            <img src = {slides[props.slideNumber].img} alt = {slides[props.slideNumber].img_alt} className = "carousel-image" loading = {props.lazy? 'lazy' : 'eager'}/>
            <div 
                className = "carousel-item-card card-left"
                style={{
                    display: props.lazy? 'none' : 'flex'
                }}
            >
                <h1>
                    <b>Well Clinic</b> - Стоматология для всей семьи
                </h1>
                <p>
                    Не откладывайте поход к стоматологу. Мы поможем Вам убедиться, что бояться нечего. Лечить зубы теперь легко и совершенно не больно!
                </p>
            </div>
        </div>
    )
}

export default Slide