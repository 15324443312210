import { slides } from "../../../../config/main-slider"

interface Props{
    slideNumber: number
    lazy?: boolean
}


const SlideFour = (props:Props) => {
    return (
        <div className = {`carousel-item`}>
            <img src = {slides[props.slideNumber].img} alt = {slides[props.slideNumber].img_alt} className = "carousel-image" loading = {props.lazy? 'lazy' : 'eager'}/>
            <div 
                className = "carousel-item-card card-two"
                style={{
                    display: 'flex'
                }}
            >
                <h2>
                    Выгодное лечение кариеса
                </h2>
                <h3>
                    Низкие цены на лечение кариеса любой сложности до конца июня.
                </h3>
                <h4>
                    <b>от 3 900 руб.</b>
                </h4>
            </div>
        </div>
    )
}

export default SlideFour;